import React, { useEffect, useRef, useState } from "react"
import { useStore, useStoreServices } from "../../zustand/store"
import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import * as dayjs from "dayjs"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import Loading from "../Loading"
import { toast } from "react-toastify"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { getMessage, getMessagedItem } from "../../library/functions"
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var updateLocale = require("dayjs/plugin/updateLocale")
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
})

  
// a little function to help with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 5;

const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    // padding: grid,
    // width: 250,
});

const ServiceReportColumnOrder = ({ setIsModalColumnSorterVisible, selectedData, setSelectedData }) => {
  
    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState(selectedData)
    const [hoveredIndex, setHoveredIndex] = useState(null)

    const getItemStyle = (isDragging, draggableStyle, index) => ({
        userSelect: "text",
        padding: grid, 
        margin: `0 0 ${grid}px 0`,
        zIndex: isDragging ? 1000 : "auto",
        border: isDragging ? "2px solid" : "1px solid", 
        borderColor: isDragging 
            ? "#F06400" 
            : hoveredIndex === index
            ? "#F06400" // Change border color on hover
            : "lightgrey", // Default border color
        // color: isDragging ? "black" : "#F06400", 
        borderRadius: "5px", 
        // background: isDragging ? "#F06400" : "white", 
        ...draggableStyle, // Merge draggableStyle provided by react-beautiful-dnd
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) return;
        const reorderedItems = reorder(items, result.source.index, result.destination.index);
        setItems(reorderedItems);
    };

    const handleReset = () => {
            setItems(selectedData)
    };

    const handleSave = () => {
        if (items !== selectedData) {
            setSelectedData(items)
        }
        setIsModalColumnSorterVisible(false)
    };

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <div className="mt-3">
            {isLoading ? (
                <div className="flex justify-center py-7">
                    <Loading />
                </div>
            ) : (
                <div>
                    <div className="mb-2 text-[0.8rem] text-[#F06400] text-right">
                        <p>Drag to reorder the items</p>
                    </div>
                    <div className="mb-2 text-[0.8rem]">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)}
                                            onMouseEnter={() => setHoveredIndex(index)} // Set hover state
                                            onMouseLeave={() => setHoveredIndex(null)} // Reset hover state
                                        >
                                            <span className="font-semibold px-[5px]">
                                                {index + 1}.
                                            </span>
                                            {item.id}
                                        </div>
                                        )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className="flex justify-end">
                        <div className="px-4 text-xs btn mr-3" onClick={handleReset}>
                            Reset
                        </div>
                        <div className="px-4 text-xs text-white btn btn-success" onClick={handleSave}>
                            Save
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ServiceReportColumnOrder
