import React, { useEffect, useState } from "react"
import Input from "../../inputs/Input"
import Select from "../../inputs/Select"
import TextArea from "../../inputs/TextArea"
import { motion } from "framer-motion"
import { apiUrl, config, variants } from "../../../library/constant"
import { useStoreReplacementCars, useStoreServices, useStoreTypes, useStoreWorkshops, useStoreServiceMileages } from "../../../zustand/store"
import { toast } from "react-toastify"
import axios from "axios"
import CheckBox from "../../inputs/CheckBox"
import { isLetter, isNumber } from "../../../library/functions"

const ServiceDetails = ({
  register,
  errors,
  watcher,
  requestNo,
  filteredCategory,
  setValue,
  setSelectedId,
  setSelectedFrom,
  setSelectedTo,
}) => {

  const serviceMileages = useStoreServiceMileages((state) => state.serviceMileages)
  const carsBrandTypes = useStoreTypes((state) => state.carsBrandTypes)

  const [isServiceDetailsVisible, setIsServiceDetailsVisible] = useState(true)
  const [isFetchingDriver, setIsFetchingDriver] = useState(false)
  const [isFetchingCategory, setIsFetchingCategory] = useState(false)

  const workshops = useStoreWorkshops((s) => s.workshops)

  const service_mileages = serviceMileages.map((item, index) => {
    return {
      id: item.name,
      name: item.name,
    }
  })

  const auhCategoryTypes = useStoreTypes((state) => state.auhCategoryTypes)
  const addAuhCategoryTypes = useStoreTypes((state) => state.addAuhCategoryTypes)
  const carsCategoryTypes = useStoreTypes((state) => state.carsCategoryTypes)
  const emirateTypes = useStoreTypes((state) => state.emirateTypes)
  const serviceTypes = useStoreTypes((state) => state.serviceTypes)
  const isWhat = useStoreServices((state) => state.isWhat)

  // console.log("watcher.service_type", watcher.service_type)
  const [isFoundCategory, setIsFoundCategory] = useState(false)
  const [newCategory, setNewCategory] = useState([])
  const [selectedServiceType, setSelectedServiceType] = useState(null)

  const handleServiceTypeChange = (e) => {
    e.preventDefault()
    const { value } = e.target
    if (value) {
      setSelectedServiceType(value)
    } else {
      setSelectedServiceType(0)
    }
    if(e) e.target.blur()
  }

  const handleServiceMileageChange = (e) => {
    if(e) e.target.blur()
  }

  const fetchDriver = (cat = null) => {
    // const category = cat ? cat : watcher.auh_category
    const category = typeof cat !== 'object' ? cat : watcher.auh_category
    let category_name = ""
    for (let index = 0; index < auhCategoryTypes.length; index++) {
      const element = auhCategoryTypes[index]
      console.log(`${element.id}  ${category}`)
      if (element.id === Number(category)) {
        category_name = element.actual
      }
    }

    if (watcher.plate === "") {
      toast.warning("Please Fill the Plate Number")
      return null
    }

    if (!category) {
      toast.warning("Please Select Emirates Category")
      return null
    }

    if (category_name === "") {
      toast.warning("This Emirates Category is not available")
      return null
    }

    const link = apiUrl + "/fetch_client/" + watcher.plate + category_name
    setIsFetchingDriver(true)
    axios
      .get(link, config())
      .then((res) => {
        const clients = res.data
        if (clients.length !== 0 && Array.isArray(clients)) {
          // console.log("client", clients[0])
          const client = clients[0]
          toast.success("Client Found!")
          setValue("client_api_data", JSON.stringify(client))
          setValue("client_no", client.CLIENT_NO)
          setValue("client_name", client.CLIENT_NAME)
          setValue("client_car_color", client.COLOR)
          setValue("client_car_make", client.MAKE)
          setValue("client_car_model", client.MODEL)
          setValue("client_car_year_model", client.YEAR_MODEL)
          setValue("client_car_lastknown_mileage", client.LAST_KNOWN_MILEAGE)
          setValue("driver_name", client.DRIVER_NAME)
          setValue("driver_contact", client.DRIVER_TELNO)
          setValue("driver_email", client.DRIVER_EMAIL)
          setValue("client_replacement_category", client.REPLACEMENT_CATEGORY)
          setValue("mileage", client.LAST_KNOWN_MILEAGE)
          setValue("mileageX", client.LAST_KNOWN_MILEAGE)
          setValue("nols_reg_no", client.PLATE_NO)

          // setValue("brand", carsBrandTypes.filter(item => item.name.toUpperCase() === client?.MAKE?.toUpperCase()).length > 0 ? carsBrandTypes.find(item => item.name === client.MAKE).id : "")
          // setValue("vmodel", client.MODEL.toUpperCase().trim())

          setSelectedId(null)
          setSelectedFrom(null)
          setSelectedTo(null)

          let isFindCetegory = false

          for (let index = 0; index < carsCategoryTypes.length; index++) {
            const element = carsCategoryTypes[index]
            // console.log("MATCHERS REG", element.name, client.REPLACEMENT_CATEGORY)
            if (element.name === client.REPLACEMENT_CATEGORY) {
              setValue("replacement_car_category", element.id)
              setValue("replacement_car_category_has", "1")
              isFindCetegory = true
            }
          }
          // Rav4,EX,

          setValue("vehicle_category", watcher?.vehicle_category || "")

          if (!isFindCetegory) {
            toast.warning(`"There's no Replacement Car Category named "${client.REPLACEMENT_CATEGORY}"`)
          }
        } else {
          toast.error("Client Not Found!")
          setValue("replacement_car_category_has", "0")
          setValue("replacement_car_category", "")
          setValue("client_api_data", "")
          setValue("client_no", "")
          setValue("client_name", "")
          setValue("client_car_color", "")
          setValue("client_car_make", "")
          setValue("client_car_model", "")
          setValue("client_car_year_model", "")
          setValue("client_car_lastknown_mileage", "")
          setValue("driver_name", "")
          setValue("driver_contact", "")
          setValue("driver_email", "")
          setValue("client_replacement_category", "")
          setValue("brand", "")
          setValue("vmodel", "")
        }
        setIsFetchingDriver(false)
      })
      .catch(() => {
        setIsFetchingDriver(false)
        toast.success("Client Not Found!")
      })
  }

  const handleEmirateChange = (e) => {
    // setValue("auh_category", "")
    setValue("emirate", e.target.value)
    e.target.blur()
  }
  const handleRegCategoryChange = (e) => {
    // alert(e.target.value)
    setValue("auh_category", e.target.value)
    e.target.blur()
  }

  const fetchCategory = () => {
    if (!watcher.emirate) {
      toast.warning("Please Select Emirate First")
      return null
    }

    if (watcher.plate === "") {
      toast.warning("Please Fill the Plate Number" + watcher.plate)
      return null
    }

    const emirate = watcher.emirate
    let emirate_name = ""
    for (let index = 0; index < emirateTypes.length; index++) {
      const element = emirateTypes[index]
      if (element.id === Number(emirate)) {
        emirate_name = element.name
      }
    }

    setIsFetchingCategory(true)
    const link = apiUrl + "/fetch_category/" + watcher.plate
    axios
      .get(link, config())
      .then(async (res) => {
        const categories = res.data
        // [{"RGNO":"52420DC"}]
        if (categories?.length !== 0 && Array.isArray(categories)) {
          const newCategories = []
          let RGNO = ""
          for (let index = 0; index < categories.length; index++) {
            const element = categories[index]
            let newCateg = element.RGNO.slice(-2)
            RGNO = element.RGNO
            // console.log(element.RGNO)

            let existingCategory = null
            for (let index = 0; index < auhCategoryTypes.length; index++) {
              const auhCategoryType = auhCategoryTypes[index]
              if (
                String(auhCategoryType.actual) === String(newCateg) &&
                Number(watcher.emirate) === Number(auhCategoryType.emirate)
              ) {
                existingCategory = auhCategoryType
              }
            }

            let categName = ""

            // if (emirate_name === "AUH") {
            //   const cteg = newCateg.split("")
            //   categName = newCateg + ""
            //   if (cteg[0] === "R" && isLetter(cteg[1])) {
            //     newCateg = ""
            //   }
            //   if (cteg[0] === "R" && !isLetter(cteg[1])) {
            //     let char2 = Number(cteg[1])
            //     if (char2 >= 1 && char2 <= 4) {
            //       newCateg = ""
            //     }
            //     categName = "R" + String(cteg[1])
            //   }
            // }

            if (emirate_name === "AUH") {
              const cteg = newCateg.split("")
              if (cteg[0] === 'R' && ['0', '4', '5', '6', '7', '8', '9'].includes(cteg[1])) {
                let char2 = Number(cteg[1])
                if ((char2 >= 1 && char2 <= 3) || char2 > 9) {
                  newCateg = ""
                }
                // passed in validation
                categName = "R"
                if (cteg[1] === '0') categName = "R" + String(cteg[1])
              } else if (isNumber(cteg[0]) && isNumber(cteg[1])) {
                let char = Number(cteg[0] + cteg[1])
                if (char <= 10 && char >= 51) { // need 11 to 50
                  newCateg = ""
                }
                // passed in validation
                categName = "R" + String(cteg[1])
              } else {
                newCateg = ""
              }
            }

            if (emirate_name === "GHQ") {
              const cteg = newCateg.split("")
              categName = cteg[1]
              if (cteg[0] !== "G") {
                newCateg = ""
              }
              if (isNumber(cteg[0]) && isNumber(cteg[1])) {
                newCateg = ""
              }
            }

            // if (emirate_name === "DUB") {
            //   const cteg = newCateg.split("")
            //   categName = cteg[1]
            //   if (cteg[0] !== "D") {
            //     newCateg = ""
            //   }
            //   if (cteg[0] === "D" && isNumber(cteg[1])) {
            //     newCateg = ""
            //   }
            //   if (isNumber(cteg[0]) && isNumber(cteg[1])) {
            //     newCateg = ""
            //   }
            // }

            if (emirate_name === "DUB") {
              const cteg = newCateg.split("")
              if (cteg[0] === 'D') {
                categName = cteg[1]
              } else {
                newCateg = ""
              }
            }

            // if (emirate_name === "RAK") {
            //   const cteg = newCateg.split("")
            //   categName = cteg[1]
            //   if (isNumber(cteg[0]) && isNumber(cteg[1])) {
            //     newCateg = ""
            //   }
            //   if (cteg[0] !== "R") {
            //     newCateg = ""
            //   }
            //   if (isNumber(cteg[1])) {
            //     let char2 = Number(cteg[1])
            //     if (char2 >= 4 && char2 <= 99) {
            //       newCateg = ""
            //     }
            //   }
            // }

            if (emirate_name === "RAK") {
              const cteg = newCateg.split("") // ['R', 'C']
              if (cteg[0] === 'R' && (['1', '2', '3'].includes(cteg[1]) || isLetter(cteg[1]))) {
                categName = cteg[1] // R
              } else {
                newCateg = ""
              }
            }

            if (emirate_name === "SHJ") {
              const cteg = newCateg.split("")
              categName = cteg[1]
              // if (cteg[0] === "S" && isNumber(cteg[1])) {
              //   newCateg = ""
              // }
              // if (isNumber(cteg[0]) && isNumber(cteg[1])) {
              //   newCateg = ""
              // }
              if (cteg[0] !== "S") {
                newCateg = ""
              }
            }

            if (newCateg !== "") {
              if (existingCategory === null) {
                // toast.info("need to add this")
                const submitData = {
                  emirate: Number(watcher.emirate),
                  name: String(categName),
                  actual: String(newCateg),
                }
                const { data } = await axios.post(apiUrl + "/auh_category_types", submitData, config())
                addAuhCategoryTypes(data)
                newCategories.push(data)
              } else {
                // toast.info("no need to add this")
                newCategories.push(existingCategory)
              }
            }
            
          }

          if (newCategories.length !== 0) {
            toast.success("Categories found for " + RGNO)
            setIsFoundCategory(true)
          }

          if (newCategories.length === 1) {
            setValue("auh_category", String(newCategories[0].id))
            // fetchDriver(newCategories[0].id)
          } else if (newCategories.length === 0) {
            toast.error("Categories Not Found!")
            setIsFoundCategory(true)
            setNewCategory([])
          }
          setNewCategory(newCategories)
          // matic fetch driver when category was only one result
          setTimeout(() => {
            if (newCategories.length === 1) fetchDriver(String(newCategories[0].id))
          }, 500)
        } else {
          toast.error("Categories Not Found!")
          setIsFoundCategory(true)
          setNewCategory([])
        }
        setIsFetchingCategory(false)
      })
      .catch((err) => {
        console.log(err)
        setIsFetchingCategory(false)
        toast.error("Category Not Found!")
        setIsFoundCategory(false)
        setNewCategory([])
      })
  }

  useEffect(() => {
    // console.log("isWhat", isWhat)
    if (selectedServiceType === undefined || selectedServiceType === null) {
      setSelectedServiceType(watcher?.service_type)
    }
  }, [selectedServiceType, watcher?.service_type])

  return (
    <div>
      <div className="flex flex-col mt-6">
        <div
          className="flex justify-between w-full text-xl font-semibold cursor-pointer"
          onClick={() => setIsServiceDetailsVisible(!isServiceDetailsVisible)}
        >
          <div className="flex items-center gap-4">
            <div className="text-[#f06400]">Service Details</div>
          </div>
          <i className="mx-4 fa-solid fa-chevron-down"></i>
        </div>
        <div className="w-full h-2 border-b"></div>
      </div>
      <motion.div
        animate={isServiceDetailsVisible ? "open" : "closed"}
        variants={variants}
        className="h-0 overflow-hidden"
      >
        <div className="flex mt-4 space-x-2 text-xl font-bold text-gray-500">
          <div>Request No: </div>
          <div>{requestNo}</div>
        </div>
        {isWhat === "update" ? (
          <div className="grid grid-flow-row grid-cols-3 gap-4">
            <Input
              register={register}
              name="emirate_name"
              label="Emirate"
              placeholder="Emirate"
              type="text"
              error={errors}
              disabled={true}
            />
            <Input
              register={register}
              name="plate"
              label="Plate"
              placeholder="Plate"
              type="text"
              error={errors}
              disabled={true}
            />
            <Input
              register={register}
              name="auh_category_name"
              label="Registration Category"
              placeholder="Registration Category"
              type="text"
              error={errors}
              disabled={true}
            />
          </div>
        ) : (
          <div className="grid grid-flow-row grid-cols-5 gap-4">
            <Select
              register={register}
              name="emirate"
              label="Emirate"
              placeholder="Select Emirate"
              type="text"
              condition={{ required: true }}
              error={errors}
              message="Emirate Required"
              items={emirateTypes}
              selectKey="name"
              onChange={(e) => handleEmirateChange(e)}
            />
            <Input
              register={register}
              name="plate"
              label="Plate"
              placeholder="Plate 5 Digits"
              type="text"
              condition={{ required: true }}
              error={errors}
              message="Plate Required"
            />
            <Select
              register={register}
              name="auh_category"
              label="Registration Category"
              placeholder="Select Emirate Category"
              type="text"
              condition={{ required: true }}
              error={errors}
              message="AUH Category Required"
              items={newCategory}
              selectKey="name"
              hasAddItem={false}
              onChange={(e) => handleRegCategoryChange(e)}
              showPlaceholder={newCategory.length !== 1 ? true : false}
            />

            <div className="flex items-start col-span-2 gap-2">
              {isFetchingCategory ? (
                <div className="flex items-center px-3 py-2 mt-10 text-white bg-[#F06400] rounded-lg cursor-not-allowed">
                  <div>
                    <i className="fa-solid fa-spinner-third animate-spin"></i>
                    <span className="mb-0.5 ml-2 ">Fetching Category</span>
                  </div>
                </div>
              ) : (
                <div
                  className="flex items-center px-3 py-2 mt-10 text-white bg-[#F06400] rounded-lg cursor-pointer"
                  onClick={fetchCategory}
                >
                  <div>
                    <i className="fa-solid fa-eye"></i>
                    <span className="mb-0.5 ml-2 ">Get Category</span>
                  </div>
                </div>
              )}

              {isFetchingDriver ? (
                <div className="flex items-center px-3 py-2 mt-10 text-white bg-[#F06400] rounded-lg cursor-not-allowed">
                  <div>
                    <i className="fa-solid fa-spinner-third animate-spin"></i>
                    <span className="mb-0.5 ml-2 ">Fetching Details</span>
                  </div>
                </div>
              ) : (
                <div
                  className="flex items-center px-3 py-2 mt-10 text-white bg-[#F06400] rounded-lg cursor-pointer"
                  onClick={fetchDriver}
                >
                  <div>
                    <i className="fa-solid fa-eye"></i>
                    <span className="mb-0.5 ml-2 ">Get Details</span>
                  </div>
                </div>
              )}

              {/* <div onClick={() => setValue("auh_category", "35")}>
              <i className="fa-solid fa-eye"></i>
              <span className="mb-0.5 ml-2 ">Get Details</span>
            </div> */}
            </div>
          </div>
        )}

        <div className="grid grid-flow-row grid-cols-3 gap-4">
          <Input
            register={register}
            name="client_no"
            label="Client No."
            placeholder="Client No."
            type="text"
            error={errors}
            disabled={true}
          />
          <Input
            register={register}
            name="client_name"
            label="Client Name"
            placeholder="Client Name"
            type="text"
            error={errors}
            disabled={true}
          />
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-4">
          <Input
            register={register}
            name="client_car_make"
            label="Client Car Make"
            placeholder="Client Car Make"
            type="text"
            error={errors}
            disabled={true}
          />
          <Input
            register={register}
            name="client_car_model"
            label="Client Car Model"
            placeholder="Client Car Model"
            type="text"
            error={errors}
            disabled={true}
          />
          <Input
            register={register}
            name="client_car_year_model"
            label="Client Car Year Model"
            placeholder="Client Car Year Model"
            type="text"
            error={errors}
            disabled={true}
          />
        </div>
        <div className="grid grid-flow-row grid-cols-3 gap-4">
          <Input
            register={register}
            name="nols_reg_no"
            label="NOLS Reg No."
            placeholder="Client Car Year Model"
            type="text"
            error={errors}
            disabled={true}
          />
          <Input
            register={register}
            name="client_car_color"
            label="Client Car Color"
            placeholder="Client Car Color"
            type="text"
            error={errors}
            disabled={true}
          />
          <Input
            register={register}
            name="client_car_lastknown_mileage"
            label="Client Car Last Known Mileage"
            placeholder="Client Car Lastknown Mileage"
            type="text"
            error={errors}
            disabled={true}
          />
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-4">
          <Input
            register={register}
            name="driver_name"
            label="Driver Name"
            placeholder="Driver Name"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Driver Name Required"
          />
          <Input
            register={register}
            name="driver_contact"
            label="Driver Contact"
            placeholder="Driver Contact"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Driver Contact Required"
          />
          <Input
            register={register}
            name="driver_email"
            label="Driver Email"
            placeholder="Driver Email"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Driver Email Required"
          />
        </div>

        <div className="grid grid-flow-row grid-cols-1 gap-4">
          <TextArea
            register={register}
            name="request_details"
            label="Request Detail"
            placeholder="Request Detail"
            type="text"
            error={errors}
            message="Driver Name Required"
            className="min-h-[140px]"
          />
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-4">
          <Select
            register={register}
            name="service_type"
            label="Service Type"
            placeholder="Please Select"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Service Request Required"
            className="col-span-2"
            items={serviceTypes}
            selectKey="name"
            onChange={(e) => handleServiceTypeChange(e)}
          />
          {selectedServiceType == 1 && 
            <Select
              register={register}
              name="service_mileage"
              label="Service Mileage"
              placeholder="Select Service Mileage"
              type="text"
              items={watcher?.service_mileages ? [{ id: watcher?.service_mileages, name: `${watcher?.service_mileages} (selected)` }, ...service_mileages] : service_mileages}
              selectKey="name"
              condition={{ required: true }}
              message="Service Mileage Required"
              error={errors}
              onChange={(e) => handleServiceMileageChange(e)}
            />
          }
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-4">
          <div className="flex flex-col justify-start pt-2 pb-5">
            <CheckBox id="collection_needed" setValue={setValue} value={watcher} title="Collection Needed" />
            {watcher.collection_needed && (
              <CheckBox id="rc_needed" setValue={setValue} value={watcher} title="RC Needed" />
            )}
          </div>
          <Input
            register={register}
            name="mileage"
            label="Current Mileage"
            placeholder="km"
            type="text"
            condition={{ required: true, min: watcher?.mileageX ? watcher?.mileageX : 0 }}
            error={errors}
            message="Mileage Required"
            className={`${selectedServiceType == 1 ? 'col-span-2' : 'col-span-2' }`}
          />
        </div>
        <div className="grid grid-flow-row grid-cols-1 gap-4">
          {/* <Input
            register={register}
            name="mileage"
            label="Current Mileage"
            placeholder="km"
            type="text"
            condition={{ required: true, min: watcher?.mileageX ? watcher?.mileageX : 0 }}
            error={errors}
            message="Mileage Required"
            className="col-span-2"
          /> */}
          {!watcher.rc_needed && (
            <Select
              register={register}
              name="workshop"
              label="Workshop"
              placeholder="Select Workshop"
              type="text"
              items={workshops}
              selectKey="name"
              condition={{ required: true }}
              message="Workshop Required"
              error={errors}
            />
          )}
        </div>
      </motion.div>
    </div>
  )
}

export default ServiceDetails
