import React, { useEffect, useMemo, useState } from "react"
import * as dayjs from "dayjs"
import { HashLoader } from "react-spinners"
import { useStore, useStoreAlerts, useStoreServices } from "../../zustand/store"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import View from "./View"
import ModalUni from "../modals/ModalUni"

var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var updateLocale = require("dayjs/plugin/updateLocale")
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
})

export const theEquivament = [
  {
    id: "emirate",
    key: "name",
    keys: ["emirate_data"],
    name: "Emirate",
  },
  {
    id: "plate",
    key: "plate",
    keys: [],
    name: "Plate",
  },
  {
    id: "emirates_category",
    key: "actual",
    keys: ["auh_category_data"],
    name: "Emirates category",
  },
  {
    id: "mileage",
    key: "mileage",
    keys: [],
    name: "Mileage",
  },
  {
    id: "client_api_data.PLATE_NO",
    key: "PLATE_NO",
    keys: ["client_api_data"],
    name: "PLATE NO",
  },
  {
    id: "client_api_data.OBJECT_NO",
    key: "OBJECT_NO",
    keys: ["client_api_data"],
    name: "OBJECT NO",
  },
  {
    id: "client_api_data.EMIRATES",
    key: "EMIRATES",
    keys: ["client_api_data"],
    name: "EMIRATES",
  },
  {
    id: "client_api_data.CLIENT_NO",
    key: "CLIENT_NO",
    keys: ["client_api_data"],
    name: "CLIENT NO",
  },
  {
    id: "client_api_data.CLIENT_NAME",
    key: "CLIENT_NAME",
    keys: ["client_api_data"],
    name: "CLIENT NAME",
  },
  {
    id: "client_api_data.MAKE",
    key: "MAKE",
    keys: ["client_api_data"],
    name: "MAKE",
  },
  {
    id: "client_api_data.MODEL",
    key: "MODEL",
    keys: ["client_api_data"],
    name: "MODEL",
  },
  {
    id: "client_api_data.YEAR_MODEL",
    key: "YEAR_MODEL",
    keys: ["client_api_data"],
    name: "YEAR MODEL",
  },
  {
    id: "client_api_data.COLOR",
    key: "COLOR",
    keys: ["client_api_data"],
    name: "COLOR",
  },
  {
    id: "client_api_data.LAST_KNOWN_MILEAGE",
    key: "LAST_KNOWN_MILEAGE",
    keys: ["client_api_data"],
    name: "LAST KNOWN MILEAGE",
  },
  {
    id: "client_api_data.REPLACEMENT_CATEGORY",
    key: "REPLACEMENT_CATEGORY",
    keys: ["client_api_data"],
    name: "REPLACEMENT CATEGORY",
  },
  {
    id: "client_api_data.REPLACEMENT_CARS",
    key: "REPLACEMENT_CARS",
    keys: ["client_api_data"],
    name: "REPLACEMENT CARS",
  },
  {
    id: "client_api_data.DRIVER_NAME",
    key: "DRIVER_NAME",
    keys: ["client_api_data"],
    name: "DRIVER NAME",
  },
  {
    id: "client_api_data.DRIVER_EMAIL",
    key: "DRIVER_EMAIL",
    keys: ["client_api_data"],
    name: "DRIVER EMAIL",
  },
  {
    id: "client_api_data.DRIVER_TELNO",
    key: "DRIVER_TELNO",
    keys: ["client_api_data"],
    name: "DRIVER TELNO",
  },
  {
    id: "driver_name",
    key: "driver_name",
    keys: [],
    name: "Driver name",
  },
  {
    id: "driver_contact",
    key: "driver_contact",
    keys: [],
    name: "Driver contact",
  },
  {
    id: "driver_email",
    key: "driver_email",
    keys: [],
    name: "Driver email",
  },
  {
    id: "request_details",
    key: "request_details",
    keys: [],
    name: "Request details",
  },
  {
    id: "workshop",
    key: "name",
    keys: ["workshop_data"],
    name: "Workshop",
  },
  {
    id: "collection_datetime",
    key: "collection_datetime",
    keys: [],
    name: "Collection datetime",
    type: "date",
  },
  {
    id: "collection_location",
    key: "collection_location",
    keys: [],
    name: "Collection location",
  },
  {
    id: "collection_coordinates.lat",
    key: "lat",
    keys: ["collection_coordinates"],
    name: "Lat",
  },
  {
    id: "collection_coordinates.lng",
    key: "lng",
    keys: ["collection_coordinates"],
    name: "Lng",
  },
  {
    id: "service_type",
    key: "name",
    keys: ["service_type_data"],
    name: "Service type",
  },
  {
    id: "added_date",
    key: "added_date",
    keys: [],
    name: "Added date",
    type: "date",
  },
  {
    id: "added_by",
    key: "name",
    keys: ["added_by_data"],
    name: "Added by",
  },
  {
    id: "updated_by",
    key: "name",
    keys: ["updated_by_data"],
    name: "Updated by",
  },
]

const Table = ({ searchText, selectedColumns }) => {
  // const services = useStoreServices((state) => state.services)
  // const setServices = useStoreServices((state) => state.setServices)
  const [services, setServices] = useState([])
  const [modalLoading, setModalLoading] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [openView, setOpenView] = useState(false)
  const [isWhat, setIsWhat] = useState("")
  const selectedService = useStoreServices((state) => state.selectedService)
  const runningRequestNo = useStore((s) => s.runningRequestNo)
  const setSelectedService = useStoreServices((state) => state.setSelectedService)
  const loggedUser = useStore((state) => state.loggedUser)
  const userType = useStore((state) => state.userType)
  const workshop = useStore((state) => state.workshop)

  const [count, setCount] = useState(0)
  const alerts = useStoreAlerts((state) => state.alerts)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const bodyRows = 10

  const parsingJSON = (str) => {
    try {
      const parsed = JSON.parse(str)
      return typeof parsed === 'object' && parsed !== null ? parsed : null
    } catch (e) {
      return null
    }
  }

  function formatDate(dateString, type) {
    let date;
  
    // Check if the string is in ISO 8601 format
    if (dayjs(dateString, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
      date = dayjs(dateString); // Parse as an ISO string
    }
    // Check if the string is in a time-only format (HH:mm)
    else if (/^\d{2}:\d{2}$/.test(dateString)) { 
      // Time format: "20:12"
      // Combine with today's date to create a full datetime
      date = dayjs().set('hour', parseInt(dateString.split(':')[0], 10))
                    .set('minute', parseInt(dateString.split(':')[1], 10));
    }
    // Check if the string is in a date-only format (YYYY-MM-DD)
    else if (dayjs(dateString, 'YYYY-MM-DD', true).isValid()) {
      date = dayjs(dateString); // Parse as a date
    } else {
      return 'Invalid date format';
    }
  
    // Format based on the requested type
    let formatString = '';
  
    if (type === 'date') {
      formatString = 'DD/MM/YYYY';  // For formatting as "Nov 7, 2024"
    } else if (type === 'time') {
      formatString = 'hh:mm a';  // For formatting as "12:00 am"
    } else if (type === 'datetime') {
      formatString = 'DD/MM/YYYY hh:mm a';  // For formatting as "Nov 7, 2024 12:00 am"
    }
  
    return date.format(formatString);
  }

  useEffect(() => {
    const fetchPagination = async () => {
      const res = await axios.get(apiUrl + "/services/count?search=" + searchText, config())
      let pages = parseInt((res.data - 1) / bodyRows + 1)
      setCount(res.data)
      setTotalPages(pages)
    }
    fetchPagination()
  }, [alerts, searchText])

  useEffect(() => {
    const fetchData = async () => {
      if (userType === 1) {
        const res = await axios.get(
          apiUrl + "/services?page=" + currentPage + "&count=" + bodyRows + "&search=" + searchText,
          config()
        )
        setServices(res.data)
      } else if (userType === 2) {
        const res = await axios.get(
          apiUrl +
            `/services/user/${loggedUser.id}?page=` +
            currentPage +
            "&count=" +
            bodyRows +
            "&search=" +
            searchText,
          config()
        )
        setServices(res.data)
      } else if (userType === 3 || userType === 4) {
        if (userType === 4) {
          const res = await axios.get(
            apiUrl +
              `/services/workshop/${workshop}?page=` +
              currentPage +
              "&count=" +
              bodyRows +
              "&search=" +
              searchText +
              "&usertype=" +
              userType +
              "&assigned=" +
              loggedUser.id,
            config()
          )
          setCount(res.data ? res.data.length : 0)
          setServices(res.data ?? [])
        } else {
          const res = await axios.get(
            apiUrl +
              `/services/workshop/${workshop}?page=` +
              currentPage +
              "&count=" +
              bodyRows +
              "&search=" +
              searchText,
            config()
          )
          setCount(res.data ? res.data.length : 0)
          setServices(res.data ?? [])
        }
      }
      setIsLoading(false)
    }
    fetchData()
  }, [currentPage, alerts, loggedUser, userType, workshop, runningRequestNo, selectedService, searchText])

  const nextPage = (params) => {
    if (currentPage === totalPages) return null

    setCurrentPage((state) => {
      state += 1
      return state
    })
  }

  const prevPage = (params) => {
    if (currentPage === 1) return null

    setCurrentPage((state) => {
      state -= 1
      return state
    })
  }

  const data = useMemo(() => {
    const convertedTableData = [...services]
    const tableData = []

    for (let index = 0; index < convertedTableData.length; index++) {
      let tableRow = { ...convertedTableData[index] }
      try {
        tableRow.client_api_data = JSON.parse(tableRow.client_api_data)
        tableRow.collection_coordinates = JSON.parse(tableRow.collection_coordinates)
      } catch (error) {}

      const newTableRow = {}

      const servicex = { ...tableRow }
      servicex.client_api_data = JSON.stringify(servicex.client_api_data)
      servicex.collection_coordinates = JSON.stringify(servicex.collection_coordinates)
      newTableRow.service = servicex
      newTableRow.request_nox = tableRow.request_no
      newTableRow.added_datex = tableRow.added_date
      newTableRow.stats = tableRow.status_types_data?.name
      newTableRow.sub_stats = tableRow.status_sub_types_data?.name

      for (let index = 0; index < selectedColumns.length; index++) {
        const columnx = selectedColumns[index]
        const column = theEquivament.find((obj) => obj.id === columnx.id)
        if (column) {

          if (column?.keys.length === 0) {
            try {
              if (column.type === "date") {
                if (tableRow[column.key]) {
                  newTableRow[column.id] = dayjs(tableRow[column.key]).format("MMM D, YYYY HH:mm")
                } else {
                  newTableRow[column.id] = ""
                }
              } else {
                newTableRow[column.id] = tableRow[column.key]
              }
            } catch (error) {
              newTableRow[column.id] = ""
            }
          }
          if (column?.keys.length === 1) {
            try {
              newTableRow[column.id] = tableRow[column?.keys[0]][column.key]
            } catch (error) {
              newTableRow[column.id] = ""
            }
          }
        } else {
          if (columnx?.keys.length === 0) {
            try {
              if (columnx.type === "date") {
                if (tableRow[columnx.key]) {
                  newTableRow[columnx.id] = dayjs(tableRow[columnx.key]).format("MMM D, YYYY HH:mm")
                } else {
                  newTableRow[columnx.id] = ""
                }
              } else {
                newTableRow[columnx.id] = tableRow[columnx.key]
              }
            } catch (error) {
              newTableRow[columnx.id] = ""
            }
          }
          if (columnx?.keys.length === 1) {
            console.log("row", columnx.keys[0])
            try {
              newTableRow[columnx.id] = tableRow[columnx.keys[0]][columnx.key]
            } catch (error) {
              newTableRow[columnx.id] = ""
            }
          }
          console.log("columnx", columnx)
          console.log("tableRow", tableRow)
        }
      }

      tableData.push(newTableRow)
    }
    return tableData
  }, [selectedColumns, services])

  return (
    <div className="">
      <View isWhat={isWhat} setIsWhat={setIsWhat} openView={openView} setOpenView={setOpenView} />
      {isLoading ? 
        <div className="mt-10">
          <center>
            <HashLoader color="#f97316" loading={true} size={80}/>
          </center>
        </div>
      :
        <>
          <div className="min-w-full mt-6 overflow-auto scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100 rounded-t-lg shadow-lg max-h-[calc(100vh-360px)]">
            <table className="w-full overflow-hidden bg-white table-auto whitespace-nowrap">
              <thead className="sticky top-0">
                <tr className="text-sm text-[#F06400] ring ring-[#F06400] bg-white">
                  {/* from-[#F06400] via-orange-300 to-[#F06400] */}
                  {/* <th className="py-4 pl-3 pr-3 text-left">SERVICE NUMBER</th> */}
                  
                  <th className="py-4 pl-3 pr-4 text-left">Date</th>
                  <th className="py-4 pl-3 pr-4 text-left">AE | Plate No. | Cat</th>
                  <th className="py-4 pl-3 pr-4 text-left">Obj: Num</th>
                  <th className="py-4 pl-3 pr-4 text-left">Make | Model</th>
                  <th className="py-4 pl-3 pr-4 text-left">Service Type | Description</th>
                  <th className="py-4 pl-3 pr-4 text-left">Status</th>
                  <th className="py-4 pl-3 pr-4 text-left">Driver</th>
                  <th className="py-4 pl-3 pr-4 text-left">Workshop</th>
                  <th className="py-4 pl-3 pr-4 text-left">Replacement Car</th>

                  <th className="py-4 pl-3 pr-4 text-left">Date/Time In</th>
                  <th className="py-4 pl-3 pr-4 text-left">Date/Time Out</th>
                  <th className="py-4 pl-3 pr-4 text-left">Client</th>
                  <th className="py-4 pl-3 pr-4 text-left">Input By</th>
                  <th className="py-4 pl-3 pr-3 text-left">Service Number</th>

                  {/* {selectedColumns.map((item, key) => {
                    return (
                      <th className="py-4 pl-3 pr-3 text-left" key={key}>
                        {item.name}
                      </th>
                    )
                  })} */}
                  {/* <th className="py-4 pl-3 pr-4 text-left">ACTION</th> */}
                  {/* <th className="py-4 pl-3 pr-3 text-left">STATUS</th> */}
                </tr>
              </thead>
              <tbody>
                {/* {services &&
                  data.map((service, key) => {
                    const itemArray = Object.values(service)
                    return (
                      <TableRow
                        key={key}
                        setIsWhat={setIsWhat}
                        service={service}
                        setSelectedService={setSelectedService}
                        setOpenView={setOpenView}
                        data={itemArray}
                      />
                    )
                  })} */}

                {services &&
                  services.map((service, key) => {
                    const clientAPIData = parsingJSON(service.client_api_data)
                    const leaseVehicle = parsingJSON(service.lease_vehicle)
                    return (
                      <tr 
                        className="text-[12px] cursor-pointer"
                        onClick={() => {
                          setModalLoading(true)
                          axios
                            .get(apiUrl + "/services/" + service.id, config())
                            .then((res) => {
                              setSelectedService(res.data)
                              setOpenView(true)
                              setIsWhat("View")
                              setModalLoading(false)
                            })
                            .catch((err) => {
                              console.log(err)
                              setModalLoading(false)
                            })
                        }}
                      >
                        <td className="p-4">
                          {dayjs(service.added_date).format("DD/MM/YYYY hh:mm a")}
                        </td>
                        <td className="p-4">
                          {service.emirate_data?.name} | {service.plate} | {service.auh_category}
                        </td>
                        <td className="p-4">
                          {clientAPIData?.OBJECT_NO}
                        </td>
                        <td className="p-4">
                          {service.replacement_car_data?.make} | {service.replacement_car_data?.model}
                        </td>
                        <td className="p-4">
                          <TruncatedTextWithTooltip text={`${service.service_type_data?.name} | ${service?.request_details}`} />
                        </td>
                        <td className="p-4">
                          <div className="space-y-1">
                            <div className="flex text-xs text-green-500">
                              <div className="p-2 bg-green-500 rounded-full"></div>
                              <div className="ml-1">{service.status_types_data?.name}</div>
                            </div>
                            <div className="flex text-xs text-[#F06400]">
                              <div className="p-2 bg-[#F06400] rounded-full"></div>
                              <div className="ml-1">{service.status_sub_types_data?.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className="p-4">
                          <p>Name: {service?.driver_name}</p>
                          <p>Contact Number: {service?.driver_contact}</p>
                        </td>
                        <td className="p-4">
                          {service.workshop_data?.name}
                        </td>
                        <td className="p-4">
                          {service.replacement_car_data?.model} - {service.replacement_car_data?.plate}
                        </td>
                        <td className="p-4">
                          {(leaseVehicle?.date_in && leaseVehicle?.time_in) ? `${formatDate(leaseVehicle?.date_in, 'date')} / ${formatDate(leaseVehicle?.time_in, 'time')}` : '-'}
                        </td>
                        <td className="p-4">
                          {(leaseVehicle?.date_out && leaseVehicle?.time_out) ? `${formatDate(leaseVehicle?.date_out, 'date')} / ${formatDate(leaseVehicle?.time_out, 'time')}` : '-'}
                        </td>
                        <td className="p-4">
                          {clientAPIData?.CLIENT_NAME}
                        </td>
                        <td className="p-4">
                          {service.added_by_data?.name}
                        </td>
                        <td className="p-4">
                          {service.request_no}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>

          <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
            <div>
              Showing {services ? data?.length : 0} of {count} total record
            </div>
            <div className="flex items-center mt-2 space-x-4">
              <div onClick={prevPage} className="cursor-pointer">
                Previous
              </div>
              <div>
                {currentPage}/{totalPages}
              </div>
              <div onClick={nextPage} className="cursor-pointer">
                Next
              </div>
            </div>
          </div>
        </>
      }
      <ModalUni
        isVisible={modalLoading}
        setIsVisible={() => {
          return
        }}
        size={2}
        title={"Fetching Data"}
        hasClose={false}
      ></ModalUni>
    </div>
  )
}

export default Table

const TruncatedTextWithTooltip = ({ text }) => {
  const maxLength = 30;
  const isTruncated = text.length > maxLength;
  const displayText = isTruncated ? `${text.slice(0, maxLength)}...` : text;

  return (
    <div className="relative group inline-block">
      <span className="truncate">{displayText}</span>
      {isTruncated && (
        <div className="absolute top-1/2 left-full transform -translate-y-1/2 ml-2 bg-white text-gray-500 border-2 text-xs rounded px-3 py-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10 whitespace-normal break-words w-[20rem] max-w-[40rem]">
          {text}
        </div>
      )}
    </div>
  );
}

// const TableRow = ({ setIsWhat, service, setSelectedService, setOpenView, data }) => {
//   const [modalLoading, setModalLoading] = useState(false)
//   return (
//     <tr
//       onClick={() => {
//         // setSelectedService(service.service)
//         // setOpenView(true)
//         // setIsWhat("View")
//         setModalLoading(true)
//         axios
//           .get(apiUrl + "/services/" + service.service.id, config())
//           .then((res) => {
//             setSelectedService(res.data)
//             setOpenView(true)
//             setIsWhat("View")
//             setModalLoading(false)
//           })
//           .catch((err) => {
//             console.log(err)
//             setModalLoading(false)
//           })
//       }}
//       className="cursor-pointer hover:bg-orange-50"
//     >
//       <ModalUni
//         isVisible={modalLoading}
//         setIsVisible={() => {
//           return
//         }}
//         size={2}
//         title={"Fetching Data"}
//         hasClose={false}
//       ></ModalUni>
//       <td className="py-4 pl-3">
//         <div className="flex flex-col">
//           <div>{service.request_nox}</div>
//           <div className="text-xs">{dayjs(service.added_datex).fromNow("s")} ago</div>
//         </div>
//       </td>

//       {data.map((item, key) => {
//         if (key <= 4) {
//           return null
//         }
//         return (
//           <td className="py-4 pl-3 text-xs" key={key}>
//             {item}
//           </td>
//         )
//       })}

//       <td className="py-3 pl-3 ">
//         <div className="space-y-1">
//           <div className="flex text-xs text-green-500">
//             <div className="p-2 bg-green-500 rounded-full"></div>
//             <div className="ml-1">{service.stats}</div>
//           </div>
//           <div className="flex text-xs text-[#F06400]">
//             <div className="p-2 bg-[#F06400] rounded-full"></div>
//             <div className="ml-1">{service.sub_stats}</div>
//           </div>
//         </div>
//       </td>
//     </tr>
//   )
// }
