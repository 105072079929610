import React, { useMemo, useState } from "react"
import AnimatedPage from "../components/AnimatedPage"
import ModalUni from "../components/modals/ModalUni"
import DynamicColumnChanger from "../components/service_request/DynamicColumnChanger"
import FormCreate from "../components/service_request/FormCreate"
import Table from "../components/service_request/Table"
import { useStore, useStoreMap, useStoreServices } from "../zustand/store"
import DebouceSearchInput from "../components/inputs/DebouceSearchInput"

const ServiceRequest = () => {
  const privileges = useStore((state) => state.privileges)
  const [isNewRequestOpen, setIsNewRequestOpen] = useState(false)
  const setSelectedAddress = useStoreMap((s) => s.setSelectedAddress)
  const setHoverCoordinates = useStoreMap((s) => s.setHoverCoordinates)
  const setIsWhat = useStoreServices((s) => s.setIsWhat)

  const [selectedColumns, setSelectedColumns] = useState([])

  const [searchText, setSearchText] = useState("")

  const handleOpenNewRequest = (second) => {
    setIsNewRequestOpen(true)
    setHoverCoordinates(null)
    setSelectedAddress(null)
    setIsWhat("insert")
  }

  return (
    <AnimatedPage title={"Service Requests"}>
      {/*  Modals */}
      <ModalUni
        isVisible={isNewRequestOpen}
        setIsVisible={setIsNewRequestOpen}
        title="Create Service Request"
        size={11}
      >
        <FormCreate setVisibleModal={setIsNewRequestOpen} />
      </ModalUni>
      {/* -------- */}

      <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
        <div className="flex gap-4">
          <DebouceSearchInput onTextChange={setSearchText} />
          {/* <button>
            <i className="px-3 py-2 text-2xl text-white bg-[#F06400] rounded-md fa-solid fa-filter"></i>
          </button> */}
        </div>
        <div className="flex gap-4">
          {/* <button>
            <i className="px-3 py-2 text-2xl text-white bg-[#F06400] rounded-md fa-solid fa-line-columns"></i>
          </button> */}
          {/* <DynamicColumnChanger selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} /> */}
          {privileges[0] ? (
            <button
              className="flex items-center justify-center px-8 py-3 text-lg text-white rounded-md shadow-md bg-aqua hover:bg-aqua-dark"
              onClick={handleOpenNewRequest}
            >
              <div className="mr-4">Create Service Request</div>
              <i className="text-sm fa-solid fa-plus-large"></i>
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Table searchText={searchText} selectedColumns={selectedColumns} />
    </AnimatedPage>
  )
}

export default ServiceRequest
