import React from "react"
import XLSX from "sheetjs-style"
import * as FileSaver from "file-saver"

const ExcelDownload = ({ excelData, fileName, isIcon = false }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  // Helper function to check if a value is a valid date
  const isValidReportDate = (dateString) => {
    // Regular expression to validate 'YYYY-MM-DD' format
    const shortDateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

    // Regular expression to validate 'HH:MM' format (24-hour)
    const timeFormatRegex = /^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

    // Regular expression for ISO date format
    const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,6})?\+00:00$/;
          
    if (isoFormatRegex.test(dateString)) {
      return 'iso';
    } else if (shortDateFormatRegex.test(dateString)) {
      return 'date';
    } else if (timeFormatRegex.test(dateString)) {
      return 'time';
    } else {
      return null
    }
  }

  // Helper function to format the date into 'DD/MM/YYYY hh:mm AM/PM'
  const formatReportDate = (dateString, type = null) => {
    let date;
    
    if (!dateString || !type) return dateString

    // Determine the format of the input
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      date = new Date(dateString + 'T00:00:00Z'); // Treat as date only
    } else if (/^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/.test(dateString)) {
      // Parse time input
      const [hours, minutes] = dateString.split(':');
      date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
    } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(dateString)) {
      date = new Date(dateString);
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    // Get hours and minutes for formatting
    let formattedHours = date.getHours();
    const formattedMinutes = String(date.getMinutes()).padStart(2, '0');

    // Determine AM or PM suffix
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    formattedHours = formattedHours % 12;
    formattedHours = formattedHours ? String(formattedHours).padStart(2, '0') : '12'; // the hour '0' should be '12'

    if (type === 'date') {
      return `${day}/${month}/${year}`; // Date only format
    } else if (type === 'time') {
      return `${formattedHours}:${formattedMinutes} ${ampm}`; // Time only format
    } else if (type === 'iso') {
      return `${day}/${month}/${year} ${formattedHours}:${formattedMinutes} ${ampm}`; // Date and time    
    } else {
      return dateString; // Normal string
    }

  }

  const checkAndConvertDates = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const validate = isValidReportDate(value)
      if (validate === 'iso') {
        acc[`${key}.date`] = formatReportDate(value, 'date')
        acc[`${key}.time`] = formatReportDate(value, 'time')
        delete acc[key];
      } else {
        acc[key] = formatReportDate(value, validate)
      }
      return acc;
    }, {});
  }

  const exportToExcel = () => {
    // Separate date and time
    const filteredData = excelData.map(item => checkAndConvertDates(item));

    // Create a worksheet from the filtered data
    const ws = XLSX.utils.json_to_sheet(filteredData);
    
    // Calculate column widths
    const columnWidths = [];
    for (const key in filteredData[0]) {
        const maxWidth = Math.max(...filteredData.map(row => {
            const cellValue = row[key];
            return cellValue ? String(cellValue).length : 0;
        }));
        columnWidths.push({ wpx: maxWidth * 10 }); // Adjust width as needed
    }
    
    // Set the column widths in the worksheet
    ws['!cols'] = columnWidths;

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");

    // Write the workbook to a binary array
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a blob from the binary array and save it
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}

  return (
    <div className="px-4 py-2 text-white rounded-md btn btn-success" title="Export Excel" onClick={exportToExcel}>
      {isIcon ? <i className="fa-solid fa-file-excel text-lg"/> : `Download Excel`}
    </div>
  )
}

export default ExcelDownload
