import React, { Fragment, useCallback, useEffect, useState } from "react"
import HyperTree, { useTreeState, treeHandlers } from "react-hyper-tree"
import classnames from "classnames"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import { useStoreSettings } from "../../zustand/store"
import { toast } from "react-toastify"
import { capitalizeWords } from "../../library/functions"
import { HashLoader } from "react-spinners"

import ModalUni from "../modals/ModalUni"
import ServiceReportCustomFilter from "../dashboard/ServiceReportCustomFilter"
import ServiceReportAllCustomFilter from "../dashboard/ServiceReportAllCustomFilter"

// Create a function to convert string to Capitalize
// const capitalize = (str) => {
//   return str.charAt(0).toUpperCase() + str.slice(1)
// }

const Tree = ({ data, selectedData, setSelectedData, setting, setForceUpdate }) => {

  const [isModalCustomFilterVisible, setIsModalCustomFilterVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [viewWhat, setViewWhat] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const [selectedCustomColumn, setSelectedCustomColumn] = useState(null)

  const viewCustomFilter = (second) => {
    setIsModalCustomFilterVisible(true)
    setViewWhat(second)
  }

  const { required, handlers } = useTreeState({
    id: "tree",
    data: data,
    defaultOpened: true,
    multipleSelect: true,
  })

  const renderNode = useCallback(
    ({ node, onToggle }) => {
      function createSelected(key, keys = []) {
        let id = key
        // reverse the keys array
        const reversedKeys = keys.reverse()
        for (let index = 0; index < reversedKeys.length; index++) {
          const element = reversedKeys[index]
          id = element + "." + id
        }

        const name = capitalizeWords(key.replace(/_/g, " "))

        const customFilter = []

        // console.log({
        //   id,
        //   key,
        //   keys: keys.reverse(),
        //   name,
        //   customFilter
        // })

        return {
          id,
          key,
          keys: keys.reverse(),
          name,
          customFilter
        }
      }

      const checkIfAlreadyAdded = (varSelectedData, isSelected, key, keys = []) => {
        const selected = createSelected(key, keys)
        const found = selectedData.find((item) => item.id === selected.id)
        if (found) {
          const index = selectedData.indexOf(found)
          if (!isSelected) {
            return varSelectedData.splice(index, 1)
          }
        } else {
          if (isSelected) {
            return varSelectedData.push(selected)
          }
        }
      }

      const handleOnSelect = async (selectedNode) => {
        const newData = [...selectedData]

        if (selectedNode.data.name === "Service Request Api Data") {
          treeHandlers.trees.tree.handlers.setSelected(selectedNode, false)
          treeHandlers.trees.tree.handlers.setOpen(selectedNode, true)
          return null
        }

        if (selectedNode.children.length !== 0) {
          treeHandlers.trees.tree.handlers.setSelected(selectedNode, false)
          treeHandlers.trees.tree.handlers.setOpen(selectedNode, true)
          return null
        }

        if (selectedNode.options.parent.data.name === "Service Request Api Data") {
          const key = selectedNode.data.name
          const isSelected = selectedNode.isSelected()
          checkIfAlreadyAdded(newData, isSelected, key)
        } else {
          const parentKey1 = selectedNode.options.parent.data.name
          const parentKey2 = selectedNode.options.parent.options.parent.data.name
          if (parentKey2 === "Service Request Api Data") {
            const key = selectedNode.data.name
            const isSelected = selectedNode.isSelected()
            checkIfAlreadyAdded(newData, isSelected, key, [parentKey1])
          } else {
            const parentKey3 = selectedNode.options.parent.options.parent.options.parent.data.name
            if (parentKey3 === "Service Request Api Data") {
              const key = selectedNode.data.name
              const isSelected = selectedNode.isSelected()
              checkIfAlreadyAdded(newData, isSelected, key, [parentKey2, parentKey1])
            }
          }
        }

        setSelectedData(newData)
      }

      return (
        <div className="tree-node" key={node.data.name}>
          <div
            onClick={onToggle}
            className={classnames({
              "tree-icon": true,
              "empty-icon": !node.hasChildren(),
              [node.options.opened ? "close-icon" : "open-icon"]: node.hasChildren(),
            })}
          />
          <div
            className={classnames({
              "node-content-wrapper": true,
              "node-selected": node.isSelected(),
            })}
            onClick={() => {
              treeHandlers.trees.tree.handlers.setSelected(node, !node.isSelected())
              handleOnSelect(node)
            }}
          >
            <div 
              className="titles"
              style={{color: !!node.options.childrenCount ? "#F06400" : ""}}
            >
              <div className="node-title">{node.data.name}</div>
              {node.data.title && <div className="node-subtitle">{node.data.title}</div>}
            </div>
            {/* {(!node.options.childrenCount && node.isSelected()) && (
              <div className="node-custom-condition">
                <button 
                  onClick={() => {
                    viewCustomFilter("specificCustomFilter")
                    setModalTitle("Custom Filter")
                    setSelectedCustomColumn(`${(!node.options.childrenCount && node.isSelected()) && `${node.options.parent.data.name}.`}${node.data.name}`)
                  }}
                >
                  {selectedData.filter(item => item.id === `${(!node.options.childrenCount && node.isSelected()) && `${node.options.parent.data.name}.`}${node.data.name}`.replace("Service Request Api Data.", "")).length > 0 && 
                    selectedData.find(item => item.id === `${(!node.options.childrenCount && node.isSelected()) && `${node.options.parent.data.name}.`}${node.data.name}`.replace("Service Request Api Data.", "")).hasOwnProperty('customFilter') && 
                    selectedData.find(item => item.id === `${(!node.options.childrenCount && node.isSelected()) && `${node.options.parent.data.name}.`}${node.data.name}`.replace("Service Request Api Data.", "")).customFilter.length > 0 ? 
                    <button className="relative flex items-center justify-center w-8 h-12 rounded-full">
                      <i className="fa-solid fa-filter"/>
                      <div className="absolute right-0 bottom-3">
                        <div className="w-3 h-3 bg-[#F06400] rounded-full animate-pulse"></div>
                      </div>
                    </button>
                  :  
                    <button className="relative flex items-center justify-center w-8 h-12 rounded-full">
                      <i className="fa-solid fa-filter"/>
                    </button>
                  }
                </button>
              </div>
            )} */}
            {!!node.options.childrenCount && (
              <Fragment>
                {isLoading ? 
                  <i className="fa fa-spinner fa-spin text-[#F06400]"/>
                :
                  <div className="children-length">
                    <div>{node.options.childrenCount}</div>
                  </div>
                }
              </Fragment>
            )}
            {/* {(node.data.name === "Service Request Api Data" && selectedData.length > 0) && 
              <button 
                onClick={() => {
                  viewCustomFilter("allCustomFilter")
                  setModalTitle("Custom Filter")
                  setSelectedCustomColumn(`${(!node.options.childrenCount && node.isSelected()) && `${node.options.parent.data.name}.`}${node.data.name}`)
                }}
              >
                {selectedData.filter(item => item?.customFilter?.length > 0).length > 0 ? 
                  <button className="relative flex items-center justify-center w-8 h-12 rounded-full">
                    <i className="fa-solid fa-filter"/>
                    <div className="absolute right-0 bottom-3">
                      <div className="w-3 h-3 bg-[#F06400] rounded-full animate-pulse"></div>
                    </div>
                  </button>
                :  
                  <button className="relative flex items-center justify-center w-8 h-12 rounded-full">
                    <i className="fa-solid fa-filter"/>
                  </button>
                }
              </button>
            } */}
          </div>
        </div>
      )
    },

    [selectedData]
  )

  const [xData, setXData] = useState([])

  useEffect(() => {
    const paramSetting = setting?.param ? JSON.parse(setting.param) : []
    const treeSetting = xData
    if (treeSetting.length !== 0) {
      for (let index = 0; index < paramSetting.length; index++) {
        const dataNode = paramSetting[index]
        if (dataNode?.keys?.length === 0) {
          const node = treeSetting?.find((item) => item?.data?.name === dataNode?.key)

          handlers?.setSelected(node, true)
        }

        if (dataNode?.keys?.length === 1) {
          const node = treeSetting?.find((item) => item?.data?.name === dataNode?.keys[0])
          const child = node?.children?.find((item) => item?.data?.name === dataNode?.key)
          handlers?.setSelected(child, true)
        }

        if (dataNode?.keys?.length === 2) {
          // console?.log(dataNode, treeSetting, dataNode?.keys[1])
          const node = treeSetting?.find((item) => item?.data?.name === dataNode?.keys[1])
          const child = node?.children?.find((item) => item?.data?.name === dataNode?.keys[0])
          const grandChild = child?.children?.find((item) => item?.data?.name === dataNode?.key)
          handlers.setSelected(grandChild, true)
        }

        if (dataNode?.keys?.length === 3) {
          const node = treeSetting?.find((item) => item?.data?.name === dataNode?.keys[2])
          const child = node?.children?.find((item) => item?.data?.name === dataNode?.keys[1])
          const grandChild = child?.children?.find((item) => item?.data?.name === dataNode?.keys[0])
          const grandGrandChild = grandChild?.children?.find((item) => item?.data?.name === dataNode?.key)
          handlers?.setSelected(grandGrandChild, true)
        }
      }
    }
  }, [setting, xData])

  useEffect(() => {

    // console.log("selectedData", selectedData);
    if (xData.length === 0 && required?.data[0]?.children) {
      const treeSetting = required?.data[0]?.children ?? null
      setXData(treeSetting)
      // console.log("first", setting)
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [required, xData])

  return (
    <div className="flex">
      <ModalUni isVisible={isModalCustomFilterVisible} setIsVisible={setIsModalCustomFilterVisible} size={7} title={modalTitle}>
        {viewWhat === "specificCustomFilter" ? 
          <ServiceReportCustomFilter 
            setIsModalCustomFilterVisible={setIsModalCustomFilterVisible} 
            selectedCustomColumn={selectedCustomColumn} 
            selectedData={selectedData} 
            setSelectedData={setSelectedData} 
          />
        : 
          <ServiceReportAllCustomFilter 
            setIsModalCustomFilterVisible={setIsModalCustomFilterVisible} 
            selectedCustomColumn={selectedCustomColumn} 
            selectedData={selectedData} 
            setSelectedData={setSelectedData} 
          />
        }
      </ModalUni>
      {/* pre JSON */}
      <HyperTree
        {...required}
        {...handlers}
        horizontalLineStyles={{
          stroke: "#ff8e00",
          strokeWidth: 3,
        }}
        verticalLineStyles={{
          stroke: "#f28e03",
          strokeWidth: 3,
        }}
        draggable={false}
        gapMode={"padding"}
        depthGap={20}
        disableLines={false}
        disableHorizontalLines={false}
        disableVerticalLines={false}
        verticalLineTopOffset={-17}
        verticalLineOffset={5}
        renderNode={renderNode}
      />
      {/* Pretify JSON with error of Converting circular structure to JSON */}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre>
      <pre>{JSON.stringify(selectedData, null, 2)}</pre> */}
    </div>
  )
}

export default Tree
