import React, { useEffect, useRef, useState } from "react"
import { useStore, useStoreServices } from "../../zustand/store"
import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import * as dayjs from "dayjs"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import Loading from "../Loading"
import { toast } from "react-toastify"
import { getMessage, getMessagedItem } from "../../library/functions"
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var updateLocale = require("dayjs/plugin/updateLocale")
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
})

const ServiceReportSaveTemplate = ({ viewWhat, templateId, serviceReportsSettings, saveServiceReportTemplate }) => {
  
  const [isLoading, setIsLoading] = useState(true)
  const templateNameRef = useRef(null);
  const selectedTemplate = serviceReportsSettings.filter(item => item.id == templateId).length > 0 ? serviceReportsSettings.find(item => item.id == templateId) : {}

  const handleSubmitTemplate = () => {
    const templateName = templateNameRef.current.value

    const reportsSettings = viewWhat === "renameTemplate" ? serviceReportsSettings.filter(item => item.id != templateId) : serviceReportsSettings

    if (templateName === "") {
        toast.error("Please enter a template name")
    } else if (reportsSettings.filter(item => item.type === templateName).length > 0) {
        toast.error("Template name was already exist.")
    } else {
        const id = viewWhat === "renameTemplate" ? templateId : null
        saveServiceReportTemplate(id, templateName)
    }
  }

  useEffect(() => {
    setIsLoading(false)
  }, [templateId])

  return (
    <div className="flex flex-col gap-4 mt-4">
        {isLoading ? (
            <div className="flex justify-center py-7">
                <Loading />
            </div>
        ) : (
            <div>
                <div className={"w-full form-control mb-2"}>
                    <label className="label">
                        <span className="label-text">Name</span>
                        <span className="label-text-alt">* Required</span>
                    </label>
                    <input
                        ref={templateNameRef}
                        defaultValue={viewWhat === "renameTemplate" ? selectedTemplate.type : ""}
                        type="text"
                        placeholder="ex. template 1"
                        className="w-full input input-bordered"
                        required
                    />
                    <label className="pt-0 label">
                        <span className="label-text-alt"></span>
                    </label>
                </div>
                <div className="flex justify-end">
                    <div className="px-4 text-xs text-white btn btn-success" onClick={handleSubmitTemplate}>
                        Save
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default ServiceReportSaveTemplate
