import React, { useEffect, useState, Fragment } from "react"

import { HashLoader } from "react-spinners"
import { toast } from "react-toastify"

const ServiceReportCustomFilterForm = ({ selectedData, setSelectedData, setIsModalCustomFilterVisible }) => {

  const [isFormSaving, setIsFormSaving] = useState(false)
  // Get all previous filters  
  const prevValues = []
  for (const key in selectedData) {
    if (selectedData[key].customFilter?.length > 0) {
      for (const subKey in selectedData[key].customFilter) {
        prevValues.push(selectedData[key].customFilter[subKey])
      }
    }
  }
  const [fields, setFields] = useState(prevValues.length > 0 ? prevValues : [{ seq: 1, field: "", operator: "=", logicalOp: "", inputType: "", value: "", opening: "", closing: "" }]);

  const handleAddFieldSet = () => {
    setFields([...fields, { seq: fields.length + 1, field: "", operator: "=", logicalOp: "", inputType: "", value: "", opening: "", closing: "" }])
  };

  const handleUndoFieldSet = () => {
    if (prevValues.length > 0) {
        setFields(prevValues)
    } else {
        Array.from(document.getElementsByClassName(`filter-dropdown`)).forEach((element) => {
            element.value = element.options[0].value;
        });
        Array.from(document.getElementsByClassName(`filter-field`)).forEach((element) => {
            element.value = "";
        });
        setFields([{ seq: 1, field: "", operator: "=", logicalOp: "", inputType: "", value: "", opening: "", closing: "" }])
    }
  }

  const handleClearFieldSet = () => {
    Array.from(document.getElementsByClassName(`filter-dropdown`)).forEach((element) => {
      element.value = element.options[0].value;
    });
    Array.from(document.getElementsByClassName(`filter-field`)).forEach((element) => {
      element.value = "";
    });
    setFields([{ seq: 1, field: "", operator: "=", logicalOp: "", inputType: "", value: "", opening: "", closing: "" }])
  }

  const handleRemoveFieldSet = (index) => {
    const newFields = fields.filter((_, i) => i !== index)
    // fix sequence
    for (let fieldsLength = 0; fieldsLength < newFields.length; fieldsLength++) {
        newFields[fieldsLength].seq = fieldsLength + 1
    }
    setFields(newFields)
  }

  const handleFieldChange = (index, event) => {
    const newFields = fields.map((fields, i) => {
        if (i === index) {
          return { ...fields, seq: index+1, field: event.target.value };
        }
        return fields;
      });
      setFields(newFields)
      event.target.blur()
  }

  const handleOperatorChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, operator: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };

  const handleLikeAlignChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, keywordAlign: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };

  const handleInputTypeChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, inputType: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };

  const handleValueChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    // event.target.blur()
  };

  const handleLogicalOpChange = (index, event) => {
    // console.log("logical index:", index)
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, logicalOp: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };
  
  
  const handleDateFromChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, dateFrom: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };

  const handleDateToChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, dateTo: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };

  const handleOpeningChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, opening: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };

  const handleClosingChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, seq: index+1, closing: event.target.value };
      }
      return field;
    });
    setFields(newFields)
    event.target.blur()
  };

  const handleRemoveAllSave = () => {
    setIsFormSaving(true)
    for (const key in selectedData) {
        if (selectedData[key].hasOwnProperty('customFilter')) selectedData[key].customFilter = []
    }
    setTimeout(() => {
        setIsFormSaving(false)
        setIsModalCustomFilterVisible(false)
        toast.success("Successfully Saved.")
    }, 2000);
  }

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    // setIsFormSaving(true)
    const newSelectedData = selectedData
    if (fields.length > 0 && fields[0].field !== "") {
        for (let newSelectedDataLength = 0; newSelectedDataLength < newSelectedData.length; newSelectedDataLength++) {
            const customFilter = fields.filter(item => item.field === newSelectedData[newSelectedDataLength].id.replace("Service Request Api Data.", ""))
            // console.log("customFilterBefore", customFilter)
            // fix broken sequence
            // for (let customFilterLength = 0; customFilterLength < customFilter.length; customFilterLength++) {
            //     customFilter[customFilterLength].seq = customFilterLength + 1
            // }
            // console.log("customFilterAfter", customFilter)
            newSelectedData[newSelectedDataLength].customFilter = customFilter
        }
        setSelectedData(newSelectedData)
    }
    setTimeout(() => {
        setIsFormSaving(false)
        setIsModalCustomFilterVisible(false)
        toast.success("Successfully Saved.")
    }, 2000);
  }

  return (
    <div>
        <form onSubmit={handleSubmitFilter}>
            <div className="pt-3 pb-7">
                <div className="flex flex-row font-bold mb-[20px] justify-end">
                    <button type="button" className="hover:text-[#F06400] mr-3" onClick={() => handleUndoFieldSet()}>
                        <i className="fa-solid fa-undo"/> Undo
                    </button>
                    |
                    <button type="button" className="hover:text-[#F06400] ml-3" onClick={() => handleClearFieldSet()}>
                        <i className="fa-solid fa-trash"/> Clear
                    </button>
                </div>
                <div className="container mx-auto">
                    {fields.sort((a, b) => { return a.seq - b.seq; }).map((field, index) => (
                        <div key={index} className="grid grid-cols-9 mb-[10px]">
                            {/* <label>Sequence {field.seq}</label> */}
                            {index > 0 ? 
                                <div className="col-start-1 col-end-10 my-[10px]">
                                    <select
                                        value={field.logicalOp}
                                        onChange={(e) => handleLogicalOpChange(index, e)}
                                        className="filter-dropdown input input-sm input-bordered"
                                        id={`filter-logicalOp-${index}`}
                                        required={fields.length > 1}
                                    >
                                        <option value="">Select a logical operator</option>
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                    </select>
                                </div>
                            : 
                                <div className="col-start-1 col-end-10 mb-[10px]">
                                </div>
                            }
                            <div className="col-span-1 mr-[10px]">
                                <div className={"w-full form-control"}>
                                    <label className="label">
                                    <span className="label-text font-bold">Opening</span>
                                    </label>
                                    <select
                                        value={field.opening}
                                        onChange={(e) => handleOpeningChange(index, e)}
                                        className={`filter-dropdown input input-sm input-bordered`}
                                        id={`filter-opening-${index}`}
                                        required={false}
                                    >
                                        <option value=""></option>
                                        <option value="(">(</option>
                                        <option value="((">((</option>
                                        <option value="(((">(((</option>
                                        <option value="((((">((((</option>
                                        <option value="(((((">(((((</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-2 mr-[10px]">
                                <div className={"w-full form-control"}>
                                    <label className="label">
                                    <span className="label-text font-bold">Field</span>
                                    </label>
                                    <select
                                        value={field.field}
                                        onChange={(e) => handleFieldChange(index, e)}
                                        className={`filter-dropdown input input-sm input-bordered`}
                                        id={`filter-field-${index}`}
                                        required={true}
                                    >
                                        <option value="">Select field</option>
                                        {selectedData.map((item, index) => {
                                            return <option key={index} value={item.id}>{item.id}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-2 mr-[10px]">
                                <div className={"w-full form-control"}>
                                    <label className="label">
                                    <span className="label-text font-bold">Operator</span>
                                    </label>
                                    <select
                                        value={field.operator}
                                        onChange={(e) => handleOperatorChange(index, e)}
                                        className={`filter-dropdown input input-sm input-bordered`}
                                        id={`filter-operator-${index}`}
                                        required={true}
                                    >
                                        {/* <option value="">Select an operator</option> */}
                                        <option value="=">equals to</option>
                                        <option value="!=">not equals to</option>
                                        <option value="<">less than to</option>
                                        <option value=">">greater than to</option>
                                        <option value="<=">less than equals to</option>
                                        <option value=">=">greater than equals to</option>
                                        <option value="contains">contains</option>
                                        <option value="not_contains">not contains</option>
                                        <option value="date_range">date range</option>
                                    </select>
                                </div>
                            </div>

                            {['=', '!=', '<', '>', '<=', '>='].includes(field.operator) && 
                                <>
                                    <div className="col-span-1 mr-[10px] hidden">
                                        <div className={"w-full form-control"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Value Type</span>
                                            </label>
                                            <select
                                                value={field?.inputType}
                                                onChange={(e) => handleInputTypeChange(index, e)}
                                                className={`filter-dropdown input input-sm input-bordered`}
                                                id={`filter-inputType-${index}`}
                                                required={false}
                                            >
                                                <option value={''}>Select</option>
                                                {['=', '!='].includes(field.operator) && 
                                                    <option value="text">text</option>
                                                }
                                                <option value="number">number</option>
                                                <option value="date">date</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-span-1 mr-[10px]">
                                        <div className={"w-full form-control"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Value</span>
                                            </label>
                                            <input
                                                className={`filter-field input input-sm input-bordered`}
                                                id={`filter-value-${index}`}
                                                type={`text`}
                                                defaultValue={field?.value}
                                                onChange={(e) => handleValueChange(index, e)}
                                                placeholder={`Enter a value`}
                                                required={['<', '>', '<=', '>='].includes(field.operator) ?  true : false}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {field.operator === 'contains' && 
                                <>
                                    <div className="col-span-1 mr-[10px]">
                                        <div className={"w-full form-control"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Keyword Align</span>
                                            </label>
                                            <select
                                                value={field?.keywordAlign}
                                                onChange={(e) => handleLikeAlignChange(index, e)}
                                                className={`filter-dropdown input input-sm input-bordered`}
                                                id={`filter-value-${index}`}
                                                required={true}
                                            >
                                                <option value="">Select</option>
                                                <option value="start">start</option>
                                                <option value="center">center</option>
                                                <option value="end">end</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-span-1 mr-[10px]">
                                        <div className={"w-full form-control"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Value</span>
                                            </label>
                                            <input
                                                className={`filter-field input input-sm input-bordered`}
                                                id={`filter-value-${index}`}
                                                type={`text`}
                                                defaultValue={field?.value}
                                                onChange={(e) => handleValueChange(index, e)}
                                                placeholder={`Enter a value`}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {field.operator === 'not_contains' && 
                                <>
                                    <div className="col-span-1 mr-[10px]">
                                        <div className={"w-full form-control"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Keyword Align</span>
                                            </label>
                                            <select
                                                value={field?.keywordAlign}
                                                onChange={(e) => handleLikeAlignChange(index, e)}
                                                className={`filter-dropdown input input-sm input-bordered`}
                                                id={`filter-value-${index}`}
                                                required={true}
                                            >
                                                <option value="">Select keyword align</option>
                                                <option value="start">start</option>
                                                <option value="center">center</option>
                                                <option value="end">end</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-span-1 mr-[10px]">
                                        <div className={"w-full form-control"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Value</span>
                                            </label>
                                            <input
                                                className={`filter-field input input-sm input-bordered`}
                                                id={`filter-value-${index}`}
                                                type={`text`}
                                                defaultValue={field?.value}
                                                onChange={(e) => handleValueChange(index, e)}
                                                placeholder={`Enter a value`}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {field.operator === 'date_range' && 
                                <>
                                    <div className="col-span-1 mr-[10px]">
                                        <div className={"w-[8rem] form-control fixed"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Date From</span>
                                            </label>
                                            <input
                                                className={`filter-field input input-sm input-bordered`}
                                                id={`filter-dateFrom-${index}`}
                                                type={`date`}
                                                value={field?.dateFrom}
                                                onChange={(e) => handleDateFromChange(index, e)}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-1 mr-[10px]">
                                        <div className={"w-[8rem] form-control fixed"}>
                                            <label className="label">
                                            <span className="label-text font-bold">Date To</span>
                                            </label>
                                            <input
                                                className={`filter-field input input-sm input-bordered`}
                                                id={`filter-dateTo-${index}`}
                                                type={`date`}
                                                value={field?.dateTo}
                                                onChange={(e) => handleDateToChange(index, e)}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="col-span-1 mr-[10px]">
                                <div className={"w-full form-control"}>
                                    <label className="label">
                                    <span className="label-text font-bold">Closing</span>
                                    </label>
                                    <select
                                        value={field.closing}
                                        onChange={(e) => handleClosingChange(index, e)}
                                        className={`filter-dropdown input input-sm input-bordered`}
                                        id={`filter-closing-${index}`}
                                        required={false}
                                    >
                                        <option value=""></option>
                                        <option value=")">)</option>
                                        <option value="))">))</option>
                                        <option value=")))">)))</option>
                                        <option value="))))">))))</option>
                                        <option value=")))))">)))))</option>
                                    </select>
                                </div>
                            </div>
                            {index > 0 ? 
                                <div className="col-span-1 ml-[15px]">
                                    <div className={"w-full"}>
                                        <label className="label">
                                        <span className="label-text font-bold invisible">Action</span>
                                        </label>
                                        <button type="button" className="mt-[4px]" onClick={() => handleRemoveFieldSet(index)}>
                                            <i className="fa-solid fa-trash hover:text-[#F06400] hover:text-[18px]"/>
                                        </button>
                                    </div>
                                </div>
                            : 
                                <div className="col-span-1">
                                </div>
                            }
                        </div>
                    ))}
                </div>
                {/* <div className={"w-full form-control"}>
                </div> */}
                <button type="button" onClick={handleAddFieldSet}>
                    <i className="fa-solid fa-plus"/> Add Filter
                </button>
                {/* <div className="col-span-1">
                    {JSON.stringify(fields)}
                </div> */}
            </div>
            <div className="grid grid-cols-2">
                <div className="col-span-1">
                    <div className={`${isFormSaving ? 'visible': 'invisible'}`}>
                        <HashLoader color="#f97316" loading={true} size={46}/>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="flex justify-end">
                        <button type="button" className="px-4 text-xs btn mr-3" onClick={() => handleRemoveAllSave()} disabled={isFormSaving}>
                            Clear and Save
                        </button>
                        <button type="submit" className="px-4 text-xs text-white btn btn-success" disabled={isFormSaving}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
  )
}

export default ServiceReportCustomFilterForm
